<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <v-textarea
      :disabled="disabled"
      :value="value"
      hide-details
      :rows="rows"
      :auto-grow="grow"
      :style="textareaStyle"
      @change="onChange"
    />
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeTextLong',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      height: null
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    textareaStyle() {
      let style = ''
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style = `outline: 1px solid ${outlineColor}; `
      }
      return style || false
    },
    disabled() {
      return this.state.disabled
    },
    grow() {
      return !this.cell.settings.textarea_fixed_rows
    },
    rows() {
      return parseInt(this.cell.settings.textarea_fixed_rows_count) || 1
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = builder.getCellValue(index, this.cell, this.clone, '')
      return value
    }
  },
  mounted() {
    this.applySQLData()
  },
  methods: {
    applySQLData() {
      const builder = this.data.ChecklistBuilder
      if (typeof builder.applySQLData === 'function') {
        builder.applySQLData(this.checklist.index, this.cell, this.dataViewer).then((result) => {
          if (!result || typeof result !== 'object' || !result.value) {
            return
          }
          this.onChange(result.value)
        })
      }
    },
    onChange(value) {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const previousValue = builder.getCellValue(index, this.cell, this.clone, false)
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
    }
  }
}
</script>

<style>
  .checklist-viewer .element-content.cell_type_text_long textarea {
    margin: 0px;
  }
  .checklist-viewer .element-content.cell_type_text_long .v-input__slot {
    min-height: 0px;
  }
</style>
